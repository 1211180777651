import OrderOverlay from 'containers/OrderOverlay';
import React from 'react';
import { Routes } from 'react-router';
import { Route } from 'react-router-dom';
import GeneralReport from './pages/GeneralReport';
import MerchantsReport from './pages/MerchantsReport';
import DetailMerchantReport from './pages/MerchantsReport/DetailMerchentReport';
import OrdersReport from './pages/OrdersReport';
import ReportTypes from './pages/ReportTypes';
import SalesReport from './pages/SalesReport';
import Detail from './pages/SalesReport/Detail';

function Reports() {
  return (
    <Routes>
      <Route path="/" element={<ReportTypes/>}/>
      <Route path="general" element={<GeneralReport/>}/>
      <Route path="merchants" element={<MerchantsReport/>}/>
      <Route path="merchants/:merchantId" element={<DetailMerchantReport/>}/>
      <Route path="orders" element={<OrdersReport/>}/>
      <Route path="sales" element={<SalesReport/>}>
        <Route path=":id" element={<Detail/>}>
          <Route path="order/:orderId" element={<OrderOverlay/>}/>
        </Route>
      </Route>
    </Routes>
  );
}

export default Reports;
