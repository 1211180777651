import { getExactDate } from 'containers/Reports/utils';
import dayjs from 'dayjs';
import useIsMobile from 'hooks/useIsMobile';
import React, { useEffect, useState } from 'react';
import { DatePicker, Select } from 'antd';
import { getQueryStringValue } from 'utils';

const quarterOfYear = require('dayjs/plugin/quarterOfYear');

dayjs.extend(quarterOfYear);

const { RangePicker } = DatePicker;
const { Option } = Select;

function DateFilter({ defaultValue= {}, setFilters }) {
  const isMobile = useIsMobile();
  const [dateFilter, setDateFilter] = useState({ type: 'month' });
  useEffect(() => {
    const range = getQueryStringValue('range');
    const from = getQueryStringValue('from');
    const to = getQueryStringValue('to');

    if (range) handleChangeDateRangeOptions(range);
    else if (from && to) onChangeDate([dayjs(from), dayjs(to)]);
    else handleChangeDateRangeOptions(defaultValue?.type || 'month');
  }, []);

  useEffect(() => {
    if (dateFilter?.hasOwnProperty('range')) setFilters('date', dateFilter);
  }, [dateFilter?.range]);

  const dateRangeOptions = [
    <Option value="all" key="all">All time</Option>,
    <Option value="day" key="day">Today</Option>,
    <Option value="week" key="week">This week</Option>,
    <Option value="month" key="month">This month</Option>,
    <Option value="quarter" key="quarter">This quarter</Option>,
    <Option value="last-week" key="last-week">Last week</Option>,
    <Option value="last-month" key="last-month">Last month</Option>,
    <Option value="custom" key="custom">Custom</Option>,
  ];

  const handleChangeDateRangeOptions = (value) => {
    let startDate = dayjs().startOf(value);
    let endDate = dayjs().endOf(value);

    if (value === 'all') {
      setDateFilter({ range: null, type: value });
      return;
    }
    if (value.indexOf('last') !== -1) {
      const range = value.split('-')[1];
      startDate = dayjs()[range](dayjs()[range]() - 1).startOf(range);
      endDate = dayjs()[range](dayjs()[range]() - 1).endOf(range);
    }
    setDateFilter({ range: [dayjs(getExactDate(startDate)), dayjs(getExactDate(endDate))], type: value });
  };

  const onChangeDate = (range) => {
    setDateFilter({ range, type: 'custom' });
  };
  return (
    <div>
      <Select
        value={dateFilter?.type}
        style={{ width: isMobile ? '100%' : '30%' }}
        className="md:mr-2 mb-2 md:mb-0"
        onChange={handleChangeDateRangeOptions}
      >
        {dateRangeOptions}
      </Select>
      <RangePicker
        showToday={false}
        value={dateFilter?.range}
        onChange={onChangeDate}
        style={{ width: isMobile ? '100%' : '67%' }}
      />
    </div>
  );
}
export default DateFilter;
