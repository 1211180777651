import { Col, Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

function MainReport({ data }) {

  return (
    <Row gutter={[8, 8]}>
      <Value label="Issued date" value={dayjs(data.created_at).format('MMM D, hh:mm A')}/>
      <Value
        label="Time frame"
        value={`${Math.abs(dayjs(data.start_date).diff(data.end_date, 'day'))} Days (${dayjs(data.start_date)
          .format('MMM D')} -> ${dayjs(data.end_date).format('MMM D')})`}
        size={18}/>

      <Value label="Calls" value={data.calls}/>
      <Value label="VMs left" value={data.voice_mails_left}/>
      <Value label="Emails sent" value={data.emails_sent}/>
      <Value label="Emails received" value={data.emails_received}/>

      <Value label="Interested leads" value={data.interested_leads}/>
      <Value label="Qualified leads" value={data.qualified_leads}/>
      <Value label="Orders generated" value={data.orders}/>
    </Row>
  );
}

function Value({
  label, value, size = 6, children, ...props
}) {
  return (
    <Col xs={12} sm={size}>
      <Space direction="vertical" style={{ marginTop: 8, gap: 1 }} {...props}>
        <Typography.Text className="font-bold leading-6">{label}</Typography.Text>
        <Typography.Text>{value || '-'}</Typography.Text>
      </Space>
    </Col>
  );
}

export default MainReport;
