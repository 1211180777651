import ReportLayout from 'containers/Reports/layout';
import React from 'react';
import ReportTypesContainer from './ReportTypesContainer';

function ReportTypes() {
  return (
    <ReportLayout
      title="Reports"
      description="All of your business’s data gathered here."
      backTitle=""
    >
      <ReportTypesContainer />
    </ReportLayout>
  );
}
export default ReportTypes;
