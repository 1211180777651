import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import {
  DatePicker,
  Flex,
  Select, Space, TimePicker, Typography,
} from 'antd';
import dayjs from 'dayjs';
import useIsMobile from 'hooks/useIsMobile';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import http from 'utils/http';
import SwitchInput from 'components/SwitchInput';
import { formatTime } from 'utils/date';

function SchedulingInput({
  value, onChange, merchantId, isCatering,
}) {
  const [loading, setLoading] = useState(false);
  const [hasScheduling, setHasScheduling] = useState(false);
  const [dates, setDates] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const firstRender = useRef(true);
  const isMobile = useIsMobile();

  const timeOptions = useMemo(() => {
    if (!merchantId || isCatering) return [];
    if (selectedDate === null || selectedDate === undefined) {
      return [];
    }
    const { times } = dates[selectedDate] || {};
    return times
      ?.reduce((all, d) => [...all, ...d.slots], [])
      ?.map((s, index) => ({
        value: index,
        label: `${formatTime({ fromHour: s.start })} - ${formatTime({ fromHour: s.end })}`,
        time: s,
      }));
  }, [selectedDate]);

  useEffect(() => {
    if (value) {
      setHasScheduling(true);
    }
    if (isCatering && value && !selectedDate) {
      setSelectedDate(dayjs(value));
      setSelectedTime(dayjs(value));
    }
  }, [isCatering, value]);

  useEffect(() => {
    if (!isCatering && value && dates?.length && firstRender.current) {
      firstRender.current = false;
      const dateIndex = dates.findIndex((d) => d.date === dayjs(value).format('YYYY-MM-DD'));
      const timeIndex = dates[dateIndex]?.times
        .reduce((all, d) => [...all, ...d.slots], [])
        .findIndex((t) => t.start === dayjs(value).format('HH:mm:ss'));

      setSelectedDate(dateIndex !== -1 ? dateIndex : null);
      setSelectedTime(timeIndex === -1 ? dayjs(value).format('HH:mm:ss') : timeIndex);
    }
  }, [dates, value, firstRender.current]);

  useEffect(() => {
    if (loading) return;
    if ((hasScheduling || isCatering) && selectedDate !== null && selectedDate !== -1 && selectedTime !== null && selectedTime !== -1) {
      let formattedScheduledData;
      if (isCatering) {
        formattedScheduledData = `${dayjs(selectedDate).format('YYYY-MM-DD')}T${dayjs(selectedTime).format('HH:mm:ss')}`;
      } else {
        formattedScheduledData = `${dates[selectedDate].date}T${typeof selectedTime === 'string' ? selectedTime : timeOptions[selectedTime]?.time?.start}`;
      }
      onChange(dayjs(formattedScheduledData).toISOString());
    } else {
      onChange(null);
    }
  }, [hasScheduling, selectedTime, loading]);

  useEffect(() => {
    if (merchantId && !isCatering) {
      setLoading(true);
      http
        .post(`v1/restaurants/${merchantId}/schedule-times/`, {
          items: [],
        })
        .then(({ data: { data } }) => {
          setDates(
            Object.entries(data)
              .filter(([_, times]) => times.length > 0)
              .map(([date, times], index) => ({
                index,
                label: dayjs(date)?.format('ddd, DD MMM'),
                value: index,
                date,
                times,
              })),
          );
          setLoading(false);
        });
    }
  }, [merchantId]);

  const handleSwitchScheduling = (value) => {
    setHasScheduling(value);
    if (!value) {
      setSelectedDate(null);
      setSelectedTime(null);
    }
  };

  const handleSelectDate = (value) => {
    setSelectedDate(value);
    setSelectedTime(null);
  };
  const handleSelectTime = (value) => {
    setSelectedTime(value);
  };

  return (
    <Space size="large" direction={isMobile ? 'vertical' : 'horizontal'} className="justify-between w-full lg:w-2/3">
      <SwitchInput
        label="Scheduled ordering"
        checked={hasScheduling || isCatering}
        disabled={isCatering}
        onChange={handleSwitchScheduling}
      />

      {isCatering ? (
        <Space direction={isMobile ? 'vertical' : 'horizontal'}>
          <Flex gap={8} align="center">
            <Typography.Text>Date:</Typography.Text>
            <DatePicker
              name="scheduled_for_date"
              value={selectedDate}
              onChange={handleSelectDate}
              disabled={loading}
              disabledDate={(current) => current && current < dayjs().startOf('day')}
            />
          </Flex>
          <Flex gap={8} align="center">
            <Typography.Text>Time:</Typography.Text>
            <TimePicker
              name="scheduled_for"
              value={selectedTime}
              onChange={handleSelectTime}
              disabled={loading}
              showSecond={false}
              minuteStep={15}
              format="hh:mm A"
              use12Hours
            />
          </Flex>
        </Space>
      ) : (
        <Space direction={isMobile ? 'vertical' : 'horizontal'}>
          <Flex gap={8} align="center">
            <Typography.Text>Date:</Typography.Text>
            <Select
              disabled={loading || !hasScheduling}
              style={{ width: 150 }}
              className="row-right-margin"
              name="scheduled_for_date"
              suffixIcon={<CalendarOutlined />}
              loading={loading}
              value={selectedDate}
              onChange={handleSelectDate}
              options={dates}
              allowClear
            />
          </Flex>
          <Flex gap={8} align="center">
            <Typography.Text>Time:</Typography.Text>
            <Select
              disabled={loading || !hasScheduling}
              style={{ width: 200 }}
              name="scheduled_for"
              className="row-right-margin"
              suffixIcon={<ClockCircleOutlined />}
              loading={loading}
              value={selectedTime}
              onChange={handleSelectTime}
              options={timeOptions}
              allowClear
            />
          </Flex>
        </Space>
      )}
    </Space>
  );
}

export default SchedulingInput;
