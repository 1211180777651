import { Empty } from 'antd';
import { EmptyTableIcon } from 'assets/icons';
import dayjs from 'dayjs';
import { priceTransformer } from 'utils';

export const tableLocale = {
  emptyText: <Empty description="There are no records to display for the selected date." image={<EmptyTableIcon />} />,
};

const isCountColumn = (key, parent) => typeof key === 'string' && (key?.indexOf('count') !== -1 || (parent && parent?.indexOf('count') !== -1));

export const renderPriceColumn = (price, record) => {
  if (price === '-') return price;
  return priceTransformer((Number(price) || 0)?.toFixed(2), isCountColumn(record.key, record?.parent), false);
};

export const renderBoldPriceColumn = (price) => (
  <b className={price < 0 ? 'text-danger' : ''}>
    {`${price < 0 ? '-' : ''}$${Math.abs(Number(price) || 0)?.toFixed(2)}`}
  </b>
);

export const renderPercentageColumn = (price) => `${price || 0}%`;

export const getExactDate = (date) => `${date.year()}-${date.month() + 1}-${date.date()}`;

export const getDateRange = ({ range, separator = ' to ' }) => (range?.length > 0 ?
  ` ${dayjs(range[0]).format('MMM D')}${separator}${dayjs(range[1]).format('MMM D')}`
  : '');

const needAddFilter = (filters, options) => filters && filters.length !== 0 && filters.length !== options.length;

export const getFilterQuery = ({ selected: filter, options }) => {
  if (!filter || !options) return '';
  let query = '';

  if (filter.date?.type !== 'all' && filter.date?.range) {
    query +=
    `from=${getExactDate(filter.date?.range[0])}&to=${getExactDate(filter.date?.range[1])}`;
  }
  if (needAddFilter(filter.locations, options?.locations)) {
    filter.locations?.forEach((loc) => query += `&parent_ids=${loc}`);
  } else if (!options.hasAccessToAllParents) {
    options?.locations?.forEach((loc) => query += `&parent_ids=${loc.value}`);
  }

  //TODO: replace this with has_delivery
  if (needAddFilter(filter.orderTypes, options?.orderTypes)) {
    query += `&order_type=${filter.orderTypes[0]}`;
  }
  if (needAddFilter(filter.merchantTypes, options?.merchantTypes)) {
    query += `&merchant_types=${filter.merchantTypes.join(',')}`;
  }
  if (filter.excludeStates) {
    query += `&${filter.excludeStates.map((s) => `exclude_states=${s}`).join('&')}`;
  }

  return query;
};
