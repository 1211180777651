import { ArrowRightOutlined, EditOutlined } from '@ant-design/icons';
import { Alert, Button, Flex, Space, Tag, Typography } from 'antd';
import EditForm from 'containers/Companies/Edit';
import dayjs from 'dayjs';
import React, { useState } from 'react';

function Details({ company, fetchCompany }) {
  const isCompleted = Boolean(company.website && company.address && company.sic_category);
  const [open, setOpen] = useState(false);

  const onEdit = () => {
    setOpen(true);
  };

  const onClose = (needRefresh) => {
    setOpen(false);
    if (needRefresh) {
      fetchCompany();
    }
  };
  return (
    <>
      <Flex vertical gap={8}>
        {!isCompleted && (
          <Alert message="Please complete the remaining company information." type="warning"/>
        )}
        <Flex justify="space-between" align="baseline" gap={8}>
          <Typography.Title level={2} className="mb-0 font-extrabold">
            {company.name}
          </Typography.Title>
          <Tag color={company.status === 'Customer' ? 'green' : ''}>
            {company.status}
          </Tag>

        </Flex>

        <Flex vertical gap={8}>
          <InfoRow title="Website" value={company.website} isLink/>
          <InfoRow title="Address" value={company.address}/>
          <InfoRow title="SIC category" value={company.sic_category}/>
          <InfoRow title="Creation date" value={dayjs(company.created_at).format('MMM DD, hh:mm A')}/>
        </Flex>
        <Flex>
          {isCompleted ? (
            <Button onClick={onEdit} icon={<EditOutlined/>}>
              Edit information
            </Button>
          ) : (
            <Button onClick={onEdit} type="primary">
              Complete
              <ArrowRightOutlined/>
            </Button>
          )}
        </Flex>
      </Flex>

      <EditForm company={company} open={open} onClose={onClose}/>
    </>

  );
}

function InfoRow({ title, value, isLink }) {
  const getFullUrl = (url) => {
    if (!url) {
      return '';
    }
    return url.startsWith('http') ? url : `https://${url}`;
  };

  if (!value) {
    return null;
  }
  return (
    <Flex gap={16}>
      <Typography.Text className="text-[#717679] flex-shrink-0">{title}</Typography.Text>
      {isLink ? (
        <Typography.Link href={getFullUrl(value)} target="_blank" className="font-bold">{value || '-'}</Typography.Link>
      ) : (
        <Typography.Text className="font-bold">{value || '-'}</Typography.Text>
      )}
    </Flex>
  );
}

export default Details;
