import { Divider, Flex, Skeleton, Space, Tooltip, Typography } from 'antd';
import useFetchedData from 'hooks/useFetchedData';
import { isEmpty } from 'lodash-es';
import React, { useMemo } from 'react';

function CustomFields({ meta }) {
  const { data: customFields, loading } = useFetchedData(
    `v1/integrations/close/${meta?.organization_id}/custom-types/`,
  );
  const fields = useMemo(() => {
    if (!customFields || isEmpty(meta?.custom_fields)) {
      return null;
    }
    return Object.keys(meta?.custom_fields)?.map(field => {
      const name = customFields.find(f => f.field_id === field)?.name;
      const needEllipsis = meta?.custom_fields[field]?.toString().length > 200;

      return (
        <Flex justify="space-between" gap={3} wrap="wrap">
          <Typography.Text className="font-bold">
            {name || field}
          </Typography.Text>
          <Tooltip title={needEllipsis && meta?.custom_fields[field]} placement="bottom" overlayInnerStyle={{ width: 350}}>
            <Typography.Paragraph>
              {meta?.custom_fields[field]?.toString().slice(0, 200)}
              {needEllipsis ? '...' : ''}
            </Typography.Paragraph>
          </Tooltip>
        </Flex>
      );
    });
  }, [customFields, meta]);

  return (
    <Flex vertical className="border border-1 border=[#0000000F] rounded mt-10 px-3 py-2">
      <Flex justify="space-between">
        <Typography.Title level={4} className="mb-0 font-bold">
          Custom fields ({Object.keys(meta?.custom_fields)?.length})
        </Typography.Title>
      </Flex>
      {loading && <Skeleton className="mt-2"/>}
      {!loading && (
        <Space direction="vertical" className="mt-2" split={<Divider className="my-0"/>}>
          {fields}
        </Space>
      )}
    </Flex>
  );
}

export default CustomFields;
