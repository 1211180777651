import { Divider, Flex, Space, Typography } from 'antd';
import { AdminContext } from 'constants/contexts';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import GeneratedOrders from './GeneratedOrders';

function Summary({ data }) {
  const { organization } = useContext(AdminContext);
  return (
    <div>
      <Flex vertical className="mb-5">
        <Typography.Text className="font-bold">
          Session Summary - {organization.name} Session ({dayjs(data.created_at).format('MM/DD/YYYY')})
        </Typography.Text>
        <Space>
          <Typography.Text className="font-bold">
            Brand Manager:
          </Typography.Text>
          <Typography.Text>
            {data.brand_manager_name}
          </Typography.Text>
        </Space>
        <Space>
          <Typography.Text className="font-bold">
            Brand:
          </Typography.Text>
          <Typography.Text>
            {organization.name}
          </Typography.Text>
        </Space>
      </Flex>

      <ListSection title="Summary" items={[data.summary]}/>

      <ListSection title="Key Highlights" items={data.key_highlights}/>

      <ListSection title="Best Opportunity" items={data.best_opportunities}/>

      <ListSection title="Other Opportunities" listStyle="decimal" items={data.other_opportunities}/>

      {data.potential_next_steps && (
        <ListSection title="Potential Next Steps (AI generated)" items={data.potential_next_steps}/>
      )}

      <Divider/>

      <GeneratedOrders orders={data.related_orders} />
    </div>
  );
}

function ListSection({ title, listStyle = 'disc', items, renderItem }) {
  const List = listStyle === 'disc' ? 'ul' : 'ol';

  if (!items || !items.length) {
    return null;
  }
  return (
    <Flex vertical className="mb-5">
      {title && <Typography.Text className="font-bold mb-2">{title}:</Typography.Text>}
      <List className={`list-${listStyle} ml-3`}>
        {items?.map((item, index) => (
          <li key={index} className="leading-5">{renderItem ? renderItem(item) : item}</li>
        ))}
      </List>
    </Flex>
  );
}

export default Summary;
