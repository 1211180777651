import dayjs from 'dayjs';
import { Link } from 'react-router';

const columns = [
  {
    title: 'ID',
    key: 'id',
    width: '5%',
  },
  {
    title: 'issue date',
    key: 'created_at',
    width: '15%',
    render: (value) => dayjs(value).format('MMM D, hh:mm A'),
  },
  {
    title: 'time frame',
    key: 'start_date',
    width: '20%',
    render: (value, record) => {
      const timeFrame = dayjs(value).diff(record.end_date, 'day');
      return `${Math.abs(timeFrame)} Days (${dayjs(value).format('MMM D')} -> ${dayjs(record.end_date)
        .format('MMM D')})`;
    },
  },
  {
    title: '#calls',
    key: 'calls',
    width: '10%',
  },
  {
    title: '#interested',
    key: 'interested_leads',
    width: '10%',
  },
  {
    title: '#qualified',
    key: 'qualified_leads',
    width: '10%',
  },
  {
    title: '',
    key: 'action',
    align: 'right',
    render: (_, row) => (
      <Link to={`/console/reports/sales/${row.id}`} color="primary">Show more</Link>
    ),
  },
];
export default columns;
