import useFetchedData from 'hooks/useFetchedData';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import DetailDrawer from './DetailDrawer';
import './index.css';

function Detail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: { data } = {}, loading } = useFetchedData(
    `v1/organizations/{{organizationId}}/reports/${id}`,
  );

  const handlePrint = () => {
    const drawerBody = document.querySelector('.ant-drawer-body');
    const drawerHeader = document.querySelector('.ant-drawer-header');
    const originalTitle = document.title;
    if (!drawerBody) {
      return;
    }

    const printContainer = document.createElement('div');
    printContainer.classList.add('print-container');

    const headerClone = drawerHeader ? drawerHeader.cloneNode(true) : null;
    const bodyClone = drawerBody.cloneNode(true);

    const contentWrapper = document.createElement('div');
    contentWrapper.classList.add('ant-drawer-content');

    if (headerClone) {
      contentWrapper.appendChild(headerClone);
    }
    contentWrapper.appendChild(bodyClone);

    printContainer.appendChild(contentWrapper);
    document.body.appendChild(printContainer);
    document.title = `Sales report #${id}`;
    window.print();
    document.body.removeChild(printContainer);
    document.title = originalTitle;
  };


  function handleBack() {
    navigate('..');
  }

  return (
    <DetailDrawer
      data={data || { id }}
      loading={loading}
      onClose={handleBack}
      onPrint={handlePrint}
    />
  );
}

export default Detail;
