import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, Space, Typography,
} from 'antd';
import GlobeIcon from 'components/GlobeIcon';
import Layout from 'components/Layout';
import Table from 'components/Table';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Outlet, useNavigate } from 'react-router';
import http from 'utils/http';
import { generateURLWithParams } from 'utils';

function List() {
  const navigate = useNavigate();
  const [search, setSearch] = useState();
  const [version, setVersion] = useState(1);
  const { pathname } = useLocation();

  useEffect(() => {
    setVersion((v) => v + 1);
  }, [pathname]);

  const columns = useMemo(() => [
    'title',
    'delivery radius',
    {
      title: 'created at',
      width: '30%',
      render: Table.RENDER_TYPES.DATE,
    },
    {
      title: '',
      render(_, { id }) {
        return (
          <Space>
            <Button
              size="small"
              icon={<EditOutlined />}
              onClick={() => navigate(`/console/locations/${id}`)}
            >
              Edit
            </Button>
          </Space>
        );
      },
    },

  ], [history]);
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const fetcher = useCallback((pagination) => http.get(generateURLWithParams(
    'v1/organization/{{organizationId}}/parents/',
    {
      search,
      page: search ? 1 : pagination.current,
      page_size: pagination.pageSize,
      ordering: '-created_at',
    },
  )).then(({ data: { results: data = [], count: total } }) => ({
    data,
    total,
  })), [search, version]);

  return (
    <Layout header={(
      <Layout.Header
        title="Locations"
        onSearch={handleSearchChange}
        extra={[
          <Link to="/console/locations/create">
            <Button
              type="default"
              size="middle"
              icon={<PlusOutlined />}
            >
              Create new
            </Button>
          </Link>,
        ]}
      />
    )}
    >
      <Table
        id="locations"
        fetchFn={fetcher}
        columns={columns}
        className="mt-8"
        empty={(
          <Space direction="vertical" className="my-10 mx-5">
            <GlobeIcon />
            <Typography.Paragraph>You don't have any Locations!</Typography.Paragraph>
            <Typography.Paragraph type="secondary">Create a new location to this account for your merchants.</Typography.Paragraph>
            <Button
              onClick={() => {
                navigate('/console/locations/create');
              }}
              type="primary"
              icon={<PlusOutlined />}
            >
              Create new location!
            </Button>
          </Space>
        )}
        scroll={{ x: 400 }}
      />

      <Outlet />
    </Layout>
  );
}

export default List;
