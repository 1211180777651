import { Layout as AntLayout } from 'antd';
import usePersistedState from 'hooks/usePersistedState';
import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';

function Layout({
  hasSidebar = true, header, children, overflowX = 'scroll',
}) {
  const [collapsed, setCollapsed] = usePersistedState(
    false,
    'sidebar/collapsed',
  );
  if (!hasSidebar) {
    return (
      <AntLayout className="site-layout">
        <AntLayout.Content style={{ overflowX: 'scroll' }}>
          {children}
        </AntLayout.Content>
      </AntLayout>
    );
  }
  return (
    <AntLayout style={{ minHeight: '100vh' }} hasSider>
      <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      <AntLayout className="site-layout" style={{ marginLeft: collapsed ? 80 : 240 }}>
        <div>
          {header}
          <AntLayout.Content style={{ overflowX }}>
            <div className="ml-5 lg:ml-16 mr-4 lg:mr-8 mt-2 lg:mt-4 mb-10">
              {children}
            </div>
          </AntLayout.Content>
        </div>
      </AntLayout>
    </AntLayout>

  );
}

Layout.Header = Header;
export default Layout;
