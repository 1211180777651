import IntercomSDK from '@intercom/messenger-js-sdk';
import { AdminContext } from 'constants/contexts';
import { useContext } from 'react';

export default function Intercom() {
  const { user, isLoggedIn } = useContext(AdminContext);
  const config = {
    app_id: 'ienxrwt3',
  };
  if (user) {
    if (user.pk) {
      config.user_id = user.pk;
    }
    if (user.first_name) {
      config.name = user.first_name;
    }
    if (user.email) {
      config.email = user.email;
    }
  }

  if (user && isLoggedIn) {
    IntercomSDK(config);
  }

  return null;
}
