import { Empty, Flex, Table, Typography } from 'antd';
import { EmptyTableIcon } from 'assets/icons';
import React from 'react';
import { Link, Outlet } from 'react-router';
import { useParams } from 'react-router-dom';
import { priceTransformer } from 'utils';

function GeneratedOrders({ orders }) {
  const { id } = useParams();
  return (
    <Flex vertical>
      <Typography.Title level={5} className="mb-6 font-bold">Orders generated</Typography.Title>

      <Table
        columns={columns({ id })}
        dataSource={orders}
        locale={{ emptyText: <Empty description="There are no records to display." image={<EmptyTableIcon/>}/> }}
        rowClassName="bg-[#f6f8f7]"
        pagination={false}
      />

      <Outlet/>
    </Flex>
  );
}

export const columns = ({ id }) => [
  {
    title: 'NUMBER',
    dataIndex: 'order_number',
    width: '25%',
  },
  {
    title: 'ORDER TOTAL',
    dataIndex: 'total',
    width: '25%',
    render: (value) => priceTransformer(value),
  },
  {
    title: '',
    align: 'right',
    render: (_, data) => <Link to={`/console/reports/sales/${id}/order/${data.id}`} className="no-print">Details</Link>,
  },
];
export default GeneratedOrders;
