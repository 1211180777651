import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, Dropdown, Space, Spin, Tag, Typography,
} from 'antd';
import FileIcon from 'components/FileIcon';
import Layout from 'components/Layout';
import SimpleModal from 'components/SimpleModal';
import Table from 'components/Table';
import withGuard from 'components/withGuard';
import { PERMISSIONS } from 'constants/permissions';
import dayjs from 'dayjs';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { generateURLWithParams, priceTransformer } from 'utils';
import { captureException } from 'utils/errors';
import http, { getHttpErrorMessage } from 'utils/http';
import CreateForm from './Create';

function Companies() {
  const navigate = useNavigate();
  const [search, setSearch] = useState();
  const [version, setVersion] = useState(1);
  const [selectedCompany, setSelectedCompany] = useState();
  const { pathname } = useLocation();
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    setVersion((v) => v + 1);
  }, [pathname]);

  const columns = useMemo(() => [
    {
      title: 'Name',
      key: 'name',
      width: '23%',
      sorter: true,
    },
    {
      title: 'Status',
      key: 'status',
      width: '5%',
      align: 'center',
      render: (value) => (
        <Tag color={value === 'Customer' ? 'green' : ''}>{value}</Tag>
      ),
    },
    {
      title: 'SIC Category',
      key: 'sic_category',
      width: '20%',
    },
    {
      title: 'Source',
      key: 'integration_driver',
      width: '10%',
      render: (value) => !value ? 'Organic' : value === 'Close' ? 'CRM' : value,
    },
    {
      title: 'Order count',
      key: 'orders_count',
      width: '12%',
      sorter: true,
    },
    {
      title: 'total order value',
      key: 'orders_total',
      width: '15%',
      sorter: true,
      render: (value) => priceTransformer(value),
    },
    {
      title: 'last order',
      key: 'last_order',
      sorter: true,
      width: '12%',
      render: (value) => value && dayjs(value).format('MMM DD, hh:mm A'),
    },
    {
      key: 'action',
      width: '3%',
      align: 'right',
      onCell: (record) => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render(_, data) {
        return (
          <Dropdown menu={{
            items: [
              {
                key: '1',
                label: <a type="text" onClick={(e) => navigate(`/console/companies/${data.id}/info`)}>Edit</a>,
              },
              {
                key: '2',
                label: <a type="text" onClick={(e) => setSelectedCompany(data)}>Delete</a>,
              },
            ],
          }}
          >
            <EllipsisOutlined />
          </Dropdown>
        );
      },
    },

  ], [history]);
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setLoading(true);
  };

  const fetchCompanies = (queries) => {
    setLoading(true);
    return http.get(generateURLWithParams(
      'v1/organizations/{{organizationId}}/companies/',
      queries,
    )).then(({ data: { results: data = [], count: total } }) => {
      setCompanies(data);
      return {
        data,
        total,
      };
    })
      .catch(captureException)
      .finally(() => setLoading(false));
  };

  const fetcher = useCallback(
    (pagination, filter, sorter) => {
      const queries = {
        search,
        page: search ? 1 : pagination.current,
        page_size: pagination.pageSize,
        ordering: '-created_at',
        // status: statusFilter,
      };
      if (sorter?.order && sorter?.columnKey) {
        queries.ordering = `${sorter?.order === 'descend' ? '-' : ''}${sorter?.columnKey}`;
      }
      return fetchCompanies(queries);
    },
    [search, version],
  );

  const onDeleteConfirm = () => {
    setDeleteLoading(true);
    return http.delete(`v1/organizations/{{organizationId}}/companies/${selectedCompany.id}/`)
      .then(() => {
        setSelectedCompany();
        setVersion((v) => v + 1);
      })
      .catch((e) => {
        message.error(getHttpErrorMessage(e));
        captureException(e);
      })
      .finally(() => setDeleteLoading(false));
  };

  const onClose = (needRefresh) => {
    setFormIsOpen(false);
    if (needRefresh) {
      setVersion((v) => v + 1);
    }
  };

  return (
    <Layout header={(companies.length > 0 || search || loading) && (
      <Layout.Header
        title="Companies"
        onSearch={handleSearchChange}
        searchPlaceholder="Search companies"
        extra={[
          <Button
            type="default"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() => setFormIsOpen(true)}
          >
            Add
          </Button>,
        ]}
      />
    )}
    >
      {!search && !companies.length && !loading && (
        <Space direction="vertical" className="text-center justify-center w-full h-[90vh]">
          {loading && <Spin size="large" />}
          {!loading && (
            <Space direction="vertical" className="justify-center align-items-center w-full h-[90vh]">
              <FileIcon />
              <Typography.Paragraph>No companies yet—add one now to manage your leads!</Typography.Paragraph>
              <Button
                onClick={() => setFormIsOpen(true)}
                type="primary"
                icon={<PlusOutlined />}
              >
                Add your first company
              </Button>
            </Space>
          )}
        </Space>
      )}
      <div
        style={{
          display: (!!companies.length || !!search || loading) ? 'block' : 'none',
        }}
      >
        <Table
          id="companies"
          fetchFn={fetcher}
          columns={columns}
          className="mt-8"
          pagination={{ pageSize: 20 }}
          scroll={{ x: 400 }}
          empty={(
            <Space direction="vertical" className="my-10 mx-5">
              <FileIcon/>
              <Typography.Paragraph>No companies match your search criteria!</Typography.Paragraph>
            </Space>
          )}
          onRow={(record) => ({
            onClick: (e) => {
              navigate(`/console/companies/${record.id}/info`);
            },
          })}
        />

      </div>
      <CreateForm open={formIsOpen} onClose={onClose} />

      <SimpleModal
        title={`Delete ${selectedCompany?.name}`}
        open={!!selectedCompany}
        setOpen={() => setSelectedCompany()}
        submitProps={{ danger: true }}
        onSubmit={onDeleteConfirm}
        loading={deleteLoading}
        confirmButtonText="Delete"
      >
        <Typography.Text>
          By confirming, all company information will be permanently deleted.
        </Typography.Text>
      </SimpleModal>
    </Layout>
  );
}

export default withGuard(Companies, [PERMISSIONS.COMPANIES], { redirect: true });
