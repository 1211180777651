import { ReadFilled } from '@ant-design/icons';
import {
  Flex, Skeleton, Space, Spin,
  Timeline, Typography,
} from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash-es';
import React, {
  useCallback, useEffect, useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import http from 'utils/http';
import { EventItems } from './constants';

const { Text, Title } = Typography;

function Activities({ created_at }) {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [eventPages, setEventPages] = useState({});
  const [hasActivity, setHasActivity] = useState(false);
  useEffect(() => {
    const handleScroll = debounce(() => {
      const bottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 50;
      if (bottom && !loading && hasMore) {
        setPage((prev) => prev + 1);
      }
    }, 200);

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
      handleScroll.cancel(); // Clean up debounce
    };
  }, [loading, hasMore]);

  const fetchEvents = useCallback((page) => {
    setLoading(true);
    http.get(`v1/organizations/{{organizationId}}/companies/${id}/activities/?page=${page}&page_size=20`)
      .then((res) => {
        setHasActivity(Boolean(res.data.count));
        setEventPages((c) => ({ ...c, [page]: res.data.results }));
        setHasMore(!!res.data.next);
      })
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    fetchEvents(page);
  }, [page, fetchEvents]);

  const eventItems = useMemo(() => (
    Object.entries(eventPages)
      .flatMap(([page, events]) => events.map((e) => ({ ...e, page })))
      .map((event) => {
        const Component = EventItems[event.event_type]?.component;
        if (!Component) {
          return null;
        }

        return {
          dot: EventItems[event.event_type].icon,
          children: <Component event={event} refresh={() => fetchEvents(event.page)} />,
          color: EventItems[event.event_type].color,
        };
      })
      .filter(Boolean)
      .concat(loading ? [{
        dot: <Spin id="loading-dot" size="large" />,
      }] : [])
      .concat([
        {
          children: <CompanyCreatedBox dateTime={created_at} />,
        }])), [loading, eventPages]);

  return (
    <Flex vertical className={`bg-[#F5F8F7] rounded py-4 ${!loading && !hasActivity ? 'h-[80vh]' : ''}`}>
      <Title level={4} className="font-bold mb-8 px-6">
        Activities
      </Title>
      {loading && Object.keys(eventPages).length === 0 && <Skeleton active className="px-6" />}
      <div
        // onScroll={handleScroll}
        style={{
          overflowY: 'auto',
          // maxHeight: 'calc(100vh - 120px)',
          padding: '0 32px',
          marginLeft: 8,
        }}
      >
        <Timeline
          className="company-activity mt-4"
          items={eventItems}
          style={{ paddingTop: 30 }}
        />

        {!loading && !hasActivity && (
          <Space direction="vertical" align="center" className="w-full mt-[10%] text-center">
            <ReadFilled className="text-[32px] text-[#ADB2B8]"/>
            <Text className="text-[#666B6D]">There is no activities yet</Text>
          </Space>
        )}

      </div>
    </Flex>
  );
}

function CompanyCreatedBox({ dateTime }) {
  return (
    <Flex
      gap={24}
      justify="flex-end"
      align="baseline"
      style={{ width: 'calc(100% + 24px)', marginTop: 18, marginLeft: -22 }}
      wrap="wrap"
    >
      <div style={{
        flex: 1,
        height: '20px',
        border: '2px dashed rgba(5, 5, 5, 0.06)',
        borderRadius: '0 0 0 8px',
        borderRight: 'none',
        borderTop: 'none',
      }}
      />
      <Text className="font-bold">Company created!</Text>
      <Text>{dayjs(dateTime).format('MMM DD, hh:mm A')}</Text>
    </Flex>
  );
}

export default Activities;
