import { PrinterOutlined } from '@ant-design/icons';
import { Button, Drawer, Flex, Skeleton, Typography } from 'antd';
import { OCLogoIcon } from 'assets/icons';
import FormLayout from 'components/FormLayout';
import { EMPTY_OBJECT } from 'constants';
import MainReport from './MainReport';
import Summary from './Summary';
import React from 'react';

function DetailDrawer({
  data = EMPTY_OBJECT,
  loading,
  onClose,
  onPrint,
}) {
  return (
    <Drawer
      title={<Flex align="center" justify="space-between">
        <Typography.Text className="font-semibold text-base no-print">Report #{data.id}</Typography.Text>
        <OCLogoIcon className="print my-5"/>
        <Typography.Text className="print">Sales report #{data.id}</Typography.Text>
      </Flex>}
      id="report-content"
      placement="right"
      onClose={onClose}
      open
      width={720}
    >
      {loading ? (
        <FormLayout.Section divider={false} className="mt-5">
          <Skeleton paragraph={{ rows: 10 }}/>
        </FormLayout.Section>
      ) : (
        <>
          <FormLayout.Section divider={false}>
            <MainReport data={data}/>
          </FormLayout.Section>
          <FormLayout.Section title="Report summary">
            <Summary data={data}/>
          </FormLayout.Section>
        </>
      )}
      <FormLayout.Footer className="p-4 absolute w-full no-print">
        <Flex gap={4}>
          <Button disabled={loading} onClick={onClose}>
            Close
          </Button>
          <Button type="primary" onClick={onPrint} loading={loading} icon={<PrinterOutlined/>}>
            Print
          </Button>
        </Flex>
      </FormLayout.Footer>
    </Drawer>
  );
}

export default DetailDrawer;
