import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  DrawingManager,
  Polygon,
} from '@react-google-maps/api';
import ReactDOM from 'react-dom';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { normalizeLatLng } from 'utils/places';
import useRerender from 'hooks/useRerender';
import useConfig from 'hooks/useConfig';
import Map from './Map';

const POLYGON_OPTIONS = {
  fillColor: '#000',
  fillOpacity: 0.4,
  strokeWeight: 2,
  strokeColor: '#111',
  clickable: true,
  editable: false,
  visible: true,
  zIndex: 1,
  draggable: false,

};
function RegionInput({ value = [], onChange }) {
  const reRender = useRerender();
  const polygonsRef = useRef([]);
  const drawingMangerRef = useRef();
  const [initialPolygons, setInitialPolygons] = useState([]);
  const { defaultLat, defaultLong } = useConfig('general') || {};
  const actionContainerRef = useRef();
  const center = useMemo(() => {
    if (!value.length) {
      return { lat: defaultLat, lng: defaultLong };
    }
    const centers = value.map(calculateCenter);
    return calculateCenter(centers);

    function calculateCenter(points) {
      const sum = points.reduce((acc, { lat, lng }) => ({ lat: acc.lat + lat, lng: acc.lng + lng }), { lat: 0, lng: 0 });
      return { lat: sum.lat / points.length, lng: sum.lng / points.length };
    }
  }, [value, defaultLat, defaultLong]);

  function clearSelections() {
    polygonsRef.current.forEach((p) => { p.setEditable(false); });
  }

  useEffect(() => {
    if (polygonsRef.current.length || !value.length) {
      return;
    }
    setInitialPolygons(value.map((path, id) => ({ path, id })));
  }, [value]);

  function handleChange() {
    onChange(polygonsRef.current.map((p) => p.getPath().getArray().map(normalizeLatLng)));
  }
  function handleLoad(e) {
    drawingMangerRef.current = e;
    const actionContainer = document.createElement('div');
    actionContainer.style.margin = '5px';
    actionContainerRef.current = actionContainer;
    drawingMangerRef.current.map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(actionContainer);
    polygonsRef.current.map((p) => p.setMap(drawingMangerRef.current.map));
  }

  function handleNewPolygon(polygon) {
    polygonsRef.current = [...polygonsRef.current, polygon];
    setTimeout(() => {
      if (!polygon.map && drawingMangerRef.current?.map) {
        polygon.setMap(drawingMangerRef.current.map);
      }
    }, 10);
    handleChange();
    window.google.maps.event.addListener(polygon, 'click', () => {
      clearSelections();
      polygon.setEditable(true);
      reRender();
    });
    polygon.getPaths().forEach((path) => {
      window.google.maps.event.addListener(path, 'set_at', () => {
        handleChange();
      });
      window.google.maps.event.addListener(path, 'insert_at', () => {
        handleChange();
      });
    });
  }

  function renderDeleteButton() {
    const selectedPolygon = polygonsRef.current.find((p) => p.editable);
    if (selectedPolygon) {
      return ReactDOM.createPortal(
        (
          <Button
            size="small"
            type="primary"
            icon={<DeleteOutlined />}
            onClick={() => {
              polygonsRef.current = polygonsRef.current.filter((p) => selectedPolygon !== p);
              selectedPolygon.setMap(null);
              handleChange();
              reRender();
            }}
            danger
          />
        ), actionContainerRef.current,
      );
    }
    return null;
  }

  if (!(defaultLat || center?.lat)) {
    return <Spin />;
  }
  return (
    <>
      <Map height={300} zoom={10} center={center}>
        <DrawingManager
          onLoad={handleLoad}
          options={{
            drawingControl: true,
            drawingControlOptions: {
              position: 2, //  window.google.maps.ControlPosition.TOP_CENTER
              drawingModes: ['polygon'],

            },
            rectangleOptions: {
              editable: false,
            },
            polygonOptions: POLYGON_OPTIONS,
          }}
          onOverlayComplete={(e) => {
            handleNewPolygon(e.overlay);
            drawingMangerRef.current.setDrawingMode(null);
          }}
        />
        {
          initialPolygons.map((ip) => (
            <Polygon
              options={POLYGON_OPTIONS}
              onLoad={(polygon) => {
                handleNewPolygon(polygon);
                setInitialPolygons((current) => current.filter((p) => p.id !== ip.id));
              }}
              path={ip.path}
            />
          ))
        }
      </Map>
      {renderDeleteButton()}
    </>
  );
}

RegionInput.serialize = (data) => ({
  type: 'MultiPolygon',
  coordinates: data.map((polygon) => {
    const points = polygon.map((point) => [point.lng, point.lat]);
    // Backend requirement: the first point should be repeated at end
    if (
      points[0][0] !== points[points.length - 1][0] ||
        points[0][1] !== points[points.length - 1][1]
    ) {
      points.push(points[0]);
    }
    return [points];
  }),
});
RegionInput.deserialize = (data = { coordinates: [] }) => data?.coordinates?.map((group) => group[0].map(([lng, lat]) => ({ lat, lng })));
export default RegionInput;
